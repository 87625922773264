<template>
  <div v-if="loading" class="loading-overlay">
    <div class="loading-spinner">
      <div class="spinner"></div>
      <p style="margin-top: 24px">Loading...</p>
    </div>
  </div>
  <div v-else>
    <div class="wrapper">
      <div class="container">

        <div class="background-div">
          <img src="/images/lw_t_yk.png" alt="" class="rotating-image image1">
          <img src="/images/sx.png" alt="" class="rotating-image image2">
        </div>

        <div class="stock-info">
          <h2 id="companyName">{{ stockSummary.companyName }} ({{ stockSummary.stockCode }})</h2>
          <p class="price">現在値:   {{ stockSummary.regularMarketPrice }}</p>
          <p class="change" :style="{ color: changeColor }">
            <span class="change-left">{{ priceChangeStr }}</span>
            <span class="change-right">{{ priceChangePercentStr }}%</span>
          </p>
          <table class="stock-info-table">
            <tr>
              <td>前日終値</td>
              <td>{{ stockSummary.chartPreviousClose }}</td>
            </tr>
            <tr>
              <td>日中高値</td>
              <td>{{ stockSummary.regularMarketDayHigh }}</td>
            </tr>
            <tr>
              <td>日中安値</td>
              <td>{{ stockSummary.regularMarketDayLow }}</td>
            </tr>
            <tr>
              <td>売買高</td>
              <td>{{ stockSummary.regularMarketVolume }}</td>
            </tr>
          </table>

          <button class="breathing-button" @click="toJoinUs">
            <span class="text1">{{stockSummary.companyName}}</span>
            <span class="text2">・診断開始</span>
          </button>
        </div>

        <div>
          <img src="/images/bottom.jpg" style="width: 100%;margin-bottom: -10px">
        </div>

        <div class="stock-info">
          <button class="breathing-button" @click="toJoinUs">
            <span class="text1">{{stockSummary.companyName}}</span>
            <span class="text2">・診断開始</span>
          </button>
        </div>

        <section class="disclaimer-section">
          <div class="alert alert-warning text-center p-4">
            <h5 class="alert-heading">免責事項:</h5>
            <p class="mb-0" style="margin-top: 8px;font-size: 14px">
              本サイトで提供される情報は、投資の助言ではありません。投資にはリスクが伴います。市場の状況により元本割れの可能性があります。投資を行う前に、十分にご自身の判断で意思決定を行い、必要に応じて専門家の助言を求めてください。
            </p>
          </div>
        </section>

        <section class="disclaimer-section">
          <div class="alert alert-warning text-center p-4">
            <h5 class="alert-heading">リスク提示:</h5>
            <p class="mb-0" style="margin-top: 8px;font-size: 14px">
              過去の実績は将来の結果を保証するものではありません。市場の変動や経済状況によって、投資資産の価値は上下する可能性があります。損失が発生する可能性があるため、十分なリスク管理が必要です。
            </p>
          </div>
        </section>
      </div>
    </div>

    <div v-if="processDialog" class="dialog-process">
      <div class="dialog-box">
        <p>AIレポート取得中...</p>
        <div class="progress-bar">
          <div class="progress-bar-inner" :style="{ width: progress + '%' }"></div>
        </div>
      </div>
    </div>

    <div v-if="isDialogVisible" class="custom-dialog-overlay">
      <div class="custom-dialog-container">
        <!-- 右上角关闭按钮 -->
        <button class="custom-close-button" @click="closeDialog">×</button>
        <!-- 主标题 -->
        <h1 class="custom-dialog-title">あなた専用の株価予測レポートが完成しました！</h1>
        <!-- 文案 -->
        <div class="custom-dialog-content">
          <p style="margin-top: 10px">
            分析は最後の一歩だけです！今すぐ
            <span style="font-weight: bold;color: red">{{ pageData.userName }}のLINE</span>
            に株式コード
            <span style="font-weight: bold;color: #0056b3">{{ stockSummary.stockCode }}</span>
            を送信して、専用のアドバイスと個別分析を受け取りましょう。あなたの投資をよりスマートに、より効率的に！
          </p>
        </div>
        <!-- 底部按钮 -->
        <div class="custom-action-button" @click="onActionClick">
          <img src="@/assets/line_logo.svg" alt="LINE" class="icon-left"/>
          <span class="button-text">無料でレポートを入手</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      processDialog: false, // 控制弹框显示/隐藏
      progress: 0, // 进度条的百分比
      pageData: {},
      items: [
        {StockCode: "7426", StockName: "山大", Yield: "+19.57%"},
        {StockCode: "3665", StockName: "エニグモ", Yield: "+16.72%"},
        {StockCode: "9025", StockName: "鴻池運輸", Yield: "+13.33%"},
        {StockCode: "7585", StockName: "かんなん丸", Yield: "-18.69%"},
        {StockCode: "9565", StockName: "ＧＬＯＥ", Yield: "-13.81%"},
        {StockCode: "3976", StockName: "シャノン", Yield: "-11.18%"},
      ],
      stockSummary: {
        companyName: "",
        regularMarketPrice: "",
        regularMarketDayHigh: "",
        regularMarketDayLow: "",
        priceChange: "",
        priceChangePercent: "",
        regularMarketVolume: "",
        chartPreviousClose: "",
        stockCode: ""
      },
      priceChangeStr:"",  //涨跌
      priceChangePercentStr:"", //涨跌幅
      isDialogVisible: false, // 控制弹框显示/隐藏
      mainTitle: "主标题", // 主标题内容
      subTitle: "副标题", // 副标题内容
    }
  },
  computed: {
    changeColor() {
      return this.stockSummary.priceChange >= 0 ? '#ff0000' : '#00ff00'; // 红色表示上涨，绿色表示下跌
    },
  },
  mounted() {
    this.stockSummary.stockCode = '';
    this.getStockData();
  },
  methods: {
    getCodeFromURL() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('code');
    },
    async getStockData() {
      try {
        this.stockSummary.stockCode = "9432.T";
        var region = "JP";
        const apiUrl = 'https://www.usuallypolite.com/prod-api/newApi/getStockData?code=9432.T&region=' + region;
        const response = await axios.get(apiUrl);
        if (response.data.code === 200) {
          const dataObject = response.data.data;
          this.stockSummary.companyName = dataObject.companyName;
          this.stockSummary.regularMarketPrice = dataObject.regularMarketPrice;
          this.stockSummary.regularMarketDayHigh = dataObject.regularMarketDayHigh;
          this.stockSummary.regularMarketDayLow = dataObject.regularMarketDayLow;
          this.stockSummary.priceChange = dataObject.priceChange;
          this.stockSummary.priceChangePercent = dataObject.priceChangePercent;
          this.stockSummary.regularMarketVolume = dataObject.regularMarketVolume;
          this.stockSummary.chartPreviousClose = dataObject.chartPreviousClose;
          let priceChangeNumber = Number(this.stockSummary.priceChange);
          if(priceChangeNumber > 0){
            this.priceChangeStr = "上昇：" +   this.stockSummary.priceChange;
            this.priceChangePercentStr = "上昇率：" +   this.stockSummary.priceChangePercent;
          } else {
            this.priceChangeStr = "下落：" +   this.stockSummary.priceChange;
            this.priceChangePercentStr = "下落率：" +   this.stockSummary.priceChangePercent;
          }
        } else {
          console.error('Response data is missing msg field.');
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    },
    startProgress() {
      let duration = 960;
      let interval = 50; // 每次更新的时间间隔，单位毫秒
      let step = 100 / (duration / interval); // 每次增加的百分比
      let timer = setInterval(() => {
        if (this.progress >= 100) {
          clearInterval(timer); // 清除计时器
          this.processDialog = false; // 隐藏弹框
          this.progress = 0;
          this.isDialogVisible = true;
        } else {
          this.progress += step; // 增加进度
        }
      }, interval);
    },
    toJoinUs() {
      this.processDialog = true;
      this.startProgress();
    },
    closeDialog() {
      this.isDialogVisible = false; // 关闭弹框
    },
    onActionClick() {
      this.isDialogVisible = false; // 关闭弹框
      window.location.href = 'http://works.do/G7JjZWy';
    },
  }
}
</script>

<style scoped>
.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  font-size: 1.5rem;
  color: #007bff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #007bff;
}

/* 外层包裹容器 */
.wrapper {
  width: 100%; /* 占满整个屏幕宽度 */
  display: flex;
  justify-content: center; /* 水平居中显示 */
  align-items: center; /* 垂直居中显示（如果需要） */
  margin: 0; /* 确保没有外边距 */
  padding: 0; /* 确保没有内边距 */
}

/* 内层实际内容的容器 */
.container {
  width: 100%; /* 默认宽度为100% */
  max-width: 750px; /* 最大宽度为750px */
  margin: 0 auto; /* 确保内容在屏幕中间 */
}

/* 针对PC端的宽度设置 */
@media (min-width: 768px) {
  .container {
    width: 750px; /* PC端固定宽度为750px */
  }
}


@keyframes scroll-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%); /* 滚动到一半，即第一组结束，第二组开始 */
  }
}

/* 背景图片容器 */
.background-div {
  width: 100%; /* 占满父容器宽度 */
  height: 340px; /* 你可以根据需要调整高度 */
  background-size: cover; /* 确保图片覆盖整个区域 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 图片不平铺 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative;
  background-image: url("/public/images/lw_top.jpg");
}

/* 通用旋转图片样式 */
.rotating-image {
  position: absolute;
  max-width: 60%; /* 确保图片不会超出容器宽度的70% */
  max-height: 60%; /* 确保图片不会超出容器高度的70% */
  transform: translate(-50%, -50%);
  top: 15%;
  object-fit: contain; /* 保持图片比例并适应容器 */
}

/* 第一个图片的旋转动画 */
.image1 {
  animation: rotate1 12s linear infinite;
}

/* 第二个图片的旋转动画 */
.image2 {
  animation: rotate2 2s linear infinite;
}

/* 旋转动画 - 图片1 */
@keyframes rotate1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 旋转动画 - 图片2 */
@keyframes rotate2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

/* Styling for stock information section */
.stock-info {
  background-color: #02228e;
  color: #fff;
  padding: 20px;
}

.stock-info h2 {
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.stock-info .price {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.change {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.change-left {
  text-align: left;
}

.change-right {
  text-align: right;
}

.stock-info-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.stock-info-table td {
  padding: 10px;
  border: 1px solid #ffffff50;
}

.stock-info-table td:first-child {
  width: 40%;
}

.stock-info-table td:last-child {
  text-align: right; /* 添加这一行，使第二个 td 内容靠右显示 */
  width: 60%;
}

.breathing-button {
  width: 100%;
  color: white; /* 按钮文字颜色 */
  border: none; /* 移除边框 */
  padding: 8px 30px; /* 设置按钮的内边距 */
  margin-top: 20px;
  font-size: 1.2rem; /* 设置字体大小 */
  border-radius: 8px; /* 圆角按钮 */
  cursor: pointer; /* 鼠标悬停样式 */
  outline: none; /* 移除默认的焦点样式 */
  animation: breathing 3s ease-in-out infinite; /* 应用呼吸动画 */
  transition: transform 0.3s ease-in-out; /* 平滑缩放过渡效果 */
  background-image: url("/public/images/btn-bg.png");
}

/* 定义呼吸动画 */
@keyframes breathing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* 放大到 110% */
  }
  100% {
    transform: scale(1);
  }
}

/* 鼠标悬停效果 */
.breathing-button:hover {
  background-color: #0056b3; /* 改变背景颜色 */
}

.disclaimer-section {
  background-color: #02228e; /* 柔和的警示背景色 */
  padding: 20px;
}

.alert {
  border-radius: 10px; /* 圆角边框 */
  color: white; /* 深黄色用于字体，确保可读性 */
}

.alert-heading {
  font-weight: bold; /* 加粗标题 */
  font-size: 18px; /* 适合的字体大小 */
}

.dialog-process {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar-inner {
  height: 100%;
  background: #007bff;
  transition: width 0.05s linear; /* 平滑过渡效果 */
}

.custom-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* 弹框容器 */
.custom-dialog-container {
  background: white;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* 主标题 */
.custom-dialog-title {
  font-size: 20px;
  color: #051163;
}

/* 文案内容 */
.custom-dialog-content {
  font-size: 16px;
  color: #000;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 1.5;
  text-align: justify; /* 添加这一行使文本两端对齐 */
}

/* 关闭按钮 */
.custom-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #999;
  transition: color 0.2s;
}

.custom-close-button:hover {
  color: #333;
}

/* 动画按钮 */
.custom-action-button {
  position: relative; /* 建立定位上下文 */
  display: flex;
  align-items: center;
  justify-content: center; /* 水平居中 */
  background-color: #3954ff;
  color: white;
  border: none;
  padding: 10px; /* 根据需要调整内边距 */
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  animation: breathing 3s ease-in-out infinite;
}

.icon-left {
  position: absolute;
  left: 8px; /* 调整图标距离左侧的距离 */
  top: 50%;
  transform: translateY(-50%); /* 垂直居中 */
  width: 26px;
  height: 26px;
}

.button-text {
  text-align: center; /* 文本居中 */
  font-size: 12px;
}

/* 确保按钮有足够的左内边距以容纳图标 */
.custom-action-button {
  padding-left: 16px; /* 根据图标大小和左边距调整 */
}

.text1{
  color: red;
}
.text2{
  color: black;
}
</style>
